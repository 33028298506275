<template>
  <div class="ProjectLists">
    <div class="title flex-row ali-center jus-center">
      <div class="commonTitle">经典案例</div>
    </div>
    <!-- <div class="sTitle">CLASSIC CASE</div> -->
    <div class="outer5 flex-row" v-for="(item, i) in listData.records" :key="i">
      <div class="box5 flex-col">
        <div class="section2 flex-col">
          <!--          <img class="pic3" referrerpolicy="no-referrer" :src=" 'http://101.35.0.207:8888/'+item.coverImg" />-->
          <img
            class="pic3"
            referrerpolicy="no-referrer"
            :src="'http://101.35.0.207:8888/' + item.coverImg"
          />
        </div>
      </div>
      <div class="box6 flex-col">
        <span class="word8"
          >{{ item.title }}
          <div class="tu" @click="toProjectDetail(item.id)">
            <img class="imgc" :src="require('../assets/jiantou.png')" /></div
        ></span>
        <div class="group2 flex-col"></div>
        <span class="word9"></span>
        <span class="infoBox3" v-html="item.content"></span>
      </div>
    </div>
    <el-pagination
      class="elPagination"
      background
      layout="prev, pager, next"
      prev-text="上一页"
      next-text="下一页"
      :page-size="size"
      @current-change="getCurrentPage"
      :total="listData.total"
      :current-page="listData.current"
    >
    </el-pagination>
  </div>
</template>

<script>
import { getGwList } from "@/api/officialCases/officialCases.js";
export default {
  name: "ProjectLists",
  data() {
    return {
      listQuery: {
        current: 1,
        pageSize: 4,
        listMode: true,
        keyWord: "",
        subsystemIdentificationCode: "1E1FF846F78548E6AC29076215F10CDA",
      },
      listData: {},

      size: 4,

      // currentLoopData: []
      userfrom: null,
    };
  },
  methods: {
    toProjectDetail(id) {
      this.$mybus.emit("userBehavior", {
        from: this.userfrom,
        moduleLevel1: 4,
        moduleLevel2: 1,
        moduleLevel3: id,
      });
      this.$router.push({
        name: "project-detail",
        query: { detail: JSON.stringify(id) },
      });
    },
    //点击分页按钮的回调
    getCurrentPage(e) {
      this.listQuery.current = e;
      getGwList(this.listQuery).then((response) => {
        this.listData = response.data;
      });

      sessionStorage.setItem("currentPage", this.listQuery.current);
    },
    // chunk(array, size) {
    //   const length = array.length
    //   if (!length || !size || size < 1) {
    //     return []
    //   }
    //   let index = 0
    //   let resIndex = 0
    //   let result = null
    //   result = new Array(Math.ceil(length / size))
    //   while (index < length) {
    //     result[resIndex++] = array.slice(index, (index += size))
    //   }
    //   return result
    // },
  },
  created() {
    if (sessionStorage.getItem("msgInfo")) {
      //如果有就读取缓存里面的数据
      this.listQuery.current = Number(sessionStorage.getItem("currentPage"));
      console.log('111',this.listQuery.current);
      // getGwList(this.listQuery).then((response) => {
      //   this.listData = response.data;
      // });
    } else {
      this.listQuery.current = 1;
      //这个主要是从其他页面第一次进入列表页，清掉缓存里面的数据
      sessionStorage.removeItem("currentPage");
    }
    getGwList(this.listQuery).then((response) => {
      this.listData = response.data;
    });
  },
  mounted() {
    window.scrollTo(0, 0);
    this.userfrom = window.sessionStorage.getItem("from");
    this.$mybus.emit("userBehavior", {
      from: this.userfrom,
      moduleLevel1: 4,
      moduleLevel2: 1,
    });
  },
  destroyed() {
    sessionStorage.removeItem("msgInfo");
  },
};
</script>


<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #025d91;
  color: #fff;
}
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .btn-next {
  padding-left: 15px;
  padding-right: 15px;
}
</style>

<style lang="stylus" scoped>
@keyframes clip {
  0% {
    clip-path: polygon(0 0, 35% 0, 0 68%, 0 67%);
  }

  10% {
    clip-path: polygon(0 0, 35% 0, 0 99%, 0% 100%);
  }

  30% {
    clip-path: polygon(0 0, 75% 0, 34% 100%, 0 100%);
  }

  50% {
    clip-path: polygon(0 0, 100% 0, 51% 100%, 0 100%);
  }

  70% {
    clip-path: polygon(0 0, 100% 0, 74% 100%, 0 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.tu {
  width: 50px;
  height: 30px;
  position: absolute;
  right: 22%;
}

.imgc {
  width: 50px;
  height: 30px;
  background: #3EA5FF;
  top: 0px;
  opacity: 0;
  z-index: -1;
  cursor: pointer;
}

.imgb {
  width: 50px;
  height: 50px;
  background-image: url('../assets/huijiantou.png');
  background-size: cover;
}

.imgbhover {
  width: 50px;
  height: 50px;
  background-color: #3EA5FF;
}

.box6:hover .tu>.imgc {
  opacity: 1;
  z-index: 1;
  animation: clip 0.5s linear;
}

.box6:hover .tu {
  background: #3EA5FF;
}

.title {
  font-size: 26px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 37px;
  margin-bottom: 10px;
  margin-top: 70px;
}

.sTitle {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 28px;
  margin-bottom: 80px;
}

.elPagination {
  margin-top: 70px;
}

.outer5 {
  z-index: auto;
  width: 1200px;
  height: 270px;
  margin: 0 auto;
  margin-top: 60px;

  .pic3 {
    width: 400px;
    height: 290px;
    display: block;
    box-shadow: 0px 6px 20px 3px rgba(0, 0, 0, 0.1);
  }

  .box6 {
    z-index: auto;
    width: 790px;
    height: 230px;
    padding-top: 31px;

    .word8 {
      z-index: 108;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 33px;
      text-align: left;
      align-self: flex-start;
      margin-left: 80px;
      font-weight: 600;
      display: flex;
    }

    .group2 {
      z-index: 102;
      width: 790px;
      height: 1px;
      border-width: 1px;
      border: 1px solid rgba(203, 240, 255, 1);
      margin-top: 20px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        right: -30px;
        top: -15px;
        width: 9px;
        height: 9px;
        background: #019FD8;
        border: 11px solid #EDFAFF;
        border-radius: 50%;
      }
    }

    .word9 {
      z-index: 117;
      width: 300px;
      height: 0px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 14px;
      // line-height: 30px;
      text-align: left;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 15px 0 0 80px;
    }

    .infoBox3 {
      z-index: 109;
      width: 680px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 15px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 5px 0 0 80px;
      flex: 1;
      display: -webkit-box; // 
      -webkit-box-orient: vertical; // 设置弹性盒子的子元素的排列方式
      -webkit-line-clamp: 4; // 设置显示文本的行数
      text-indent:30px
    }
  }
}

.section2 {
  z-index: 106;
  height: 230px;
  background-color: rgba(194, 213, 230, 1);
  width: 360px;

  .pic3 {
    z-index: 107;
    width: 360px;
    height: 230px;
  }
}

.pic3 {
  z-index: 107;
  width: 360px;
  height: 230px;
}

.box6 {
  z-index: auto;
  width: 790px;
  height: 239px;
  padding-top: 31px;

  .word8 {
    z-index: 108;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 33px;
    text-align: left;
    align-self: flex-start;
    margin-left: 80px;
  }

  .group2 {
    z-index: 102;
    width: 790px;
    height: 1px;
    border-width: 1px;
    border: 1px solid rgba(203, 240, 255, 1);
    margin-top: 20px;
  }

  .word9 {
    z-index: 117;
    width: 79px;
    height: 30px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 15px 0 0 80px;
  }
}

.box6:hover {
  background-color: #F8F8F8;
  width: 820px;
}

.word8 {
  z-index: 108;
  width: 240px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: PingFangSC-Semibold;
  white-space: nowrap;
  line-height: 33px;
  text-align: left;
  align-self: flex-start;
  margin-left: 80px;
}

.group2 {
  z-index: 102;
  width: 790px;
  height: 1px;
  border-width: 1px;
  border: 1px solid rgba(203, 240, 255, 1);
  margin-top: 20px;
}

.word9 {
  z-index: 117;
  width: 79px;
  height: 30px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(109, 114, 120, 1);
  font-size: 14px;
  line-height: 30px;
  text-align: left;
  align-self: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 15px 0 0 80px;
}

.block3 {
  z-index: auto;
  width: 52px;
  height: 17px;
  justify-content: space-between;

  .word10 {
    z-index: 113;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(1, 159, 216, 1);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }
}

.word10 {
  z-index: 113;
  width: 36px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(1, 159, 216, 1);
  font-size: 12px;
  white-space: nowrap;
  line-height: 17px;
  text-align: left;
}

.pic4 {
  z-index: 139;
  width: 54px;
  height: 222px;
  margin: 2px 0 0 286px;
}
</style>
